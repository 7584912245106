/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
body {
    /* use the font */
    font-family: "Roboto", sans-serif;
    background-color: var(--primary-bg);
    color: var(--primary-label);
    margin: 0;
    background-attachment: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }
  
  .poster {
    min-height: 300px;
    background-color: var(--color-light);
    color: var(--primary-label-2);
  }
  
  /* carousel slideshow styles */
  .carousel-container {
    margin-top: 30px;
  }
  
  .carousel {
    margin: 20px;
    width: 90%;
    padding-left: 25px;
    border-radius: 7px;
    min-height: 300px;
    box-shadow: 0 0 5px 0 rgba(156, 156, 156, 0.2);
    position: relative;
    left: 2.5%;
    background: inherit;
    color: var(--primary-label-1);
    overflow: hidden;
  }
  
  .carousel::before {
    margin: -20px;
    border-radius: 7px;
    content: "";
    position: absolute;
    background: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(124, 124, 124, 0.5);
    filter: blur(10px);
    z-index: -1;
  }
  
  /* section-break styles */
  
  .page-break {
    margin: 0px 30px;
    border-color: var(--primary-bg-2);
  }
  
  /* info section styles */
  
  .info-link {
    min-height: 300px;
    padding: 0px 30px;
    align-items: center;
  }
  
  .info-link img {
    height: 450px;
    width: 450px;
  }
  
  .services-link {
    text-decoration: none;
    color: var(--primary-label-1);
    cursor: pointer;
    font-size: 18px;
    margin-top: 15px;
    font-weight: 300;
  }
  
  .services-link:hover {
    color: var(--color-light) !important;
    text-decoration: underline;
  }
  
  .about-description {
    color: var(--primary-label-1);
    font-size: 18px;
    font-weight: 300;
  }
  .info-link a .arrow-right {
    height: 12px;
    width: 12px;
  }
  
  .slides {
    display: none;
  }
  
  /* The pills indicators */
  
  .pill-container {
    text-align: center;
    position: relative;
    top: 2%;
  }
  .pills {
    cursor: pointer;
    height: 3px;
    width: 25px;
    margin: 2px 1px;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .pills:hover {
    background-color: var(--secondary-label-1);
    border-color: var(--primary-bg) !important;
  }

  .home-carousel .pills.active{
    background-color: var(--color-light)  !important;
    border-color: var(--primary-bg) !important;
  }
  
  /* carousel offer section styles */
  
  .offer-info {
    font-size: 60px !important;
    margin: 10px 0px;
  }
  
  /* carousel customer review section styles */
  
  .review-content div:first-child {
    font-size: 30px;
    font-weight: 300;
    margin-top: 15px;
  }
  
  .customer-review {
    margin-top: 50px;
  }
  .customer-review span {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .customer-review span img {
    height: 25px;
    width: 70px;
  }
  
  /* carousel price listing section styles */
  .price-listing span {
    font-size: 18px;
  }
  
  
  /* responsive css for home page */
  @media only screen and (max-device-width: 480px){
    .about-description, .text-description {
      display: none;
    }
  
    .poster {
      padding-right: 15px;
      align-items: center;
    }
  
    .text-header {
      font-size: 24px !important;
    }
  
    body {
      overflow-x: hidden;
  }

  .info-section-container img{
    width: 200px;
    height: 200px;
  }
}

  .info-section-container {
    background-color: var(--primary-bg);
  }
  