
/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
 * All the Color Variables for ui elements will be defined in here 
*/

:root {
  /* Background colors */
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;

  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;

  /* Label colors */

  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;

  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;

  /* State colors */
  --state-danger: #c62828;
  --state-danger-1: #b71c1c;
  --state-danger-2: #ef5350;
  --state-danger-3: #f44336;
  --state-danger-light: #ffebee;

  --state-warning: #f9a825;
  --state-warning-1: #f57f17;
  --state-warning-2: #fdd835;
  --state-warning-3: #ffff00;
  --state-warning-light: #fffde7;

  --state-success: #558b2f;
  --state-success-1: #33691e;
  --state-success-2: #8bc34a;
  --state-success-3: #b2ff59;
  --state-success-light: #f1f8e9;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-damion {
  font-family: 'Damion', cursive;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}
.align-items-between {
  align-items: space-between;
}
.align-items-around {
  align-items: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.btn {
  background-color: inherit;
  border-radius: 3px;
  padding: 5px 10px;
  border: 1px solid var(--secondary-bg-1);
  color: var(--primary-label);
}

header.nav-header {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-bg-1);
  position: sticky; /* Set the navbar to fixed position */
  top: 0;
  z-index: 1000;
}

.navbar {
  flex: 6;
}

.navbar ul {
  list-style: none;
  align-items: center;
}

.nav-Link {
  padding: 10px;
  border-radius: 4px;
}

.nav-Link:hover {
  background-color: var(--primary-bg);
  color: var(--primary-label);
}

.nav-item .nav-Link {
  text-decoration: none;
  color: var(--primary-label-1);
  font-weight: 300;
  letter-spacing: 1px;
}

.nav-item.active .nav-Link {
  color: var(--primary-label) !important;
  border: 1px solid var(--secondary-label-2);
  border-radius: 4px;
}

.nav-item:hover {
  transform: scale(1.1);
}

.header-title {
  align-items: center;
  flex: 2;
  padding-left: 10px;
  font-size: 25px;
  font-weight: 400;
  color: var(--secondary-label-2);
  letter-spacing: 1px;
}

.search-control {
  margin-bottom: 3px;
}
.search-control input {
  height: 30px;
  margin-right: 7px;
}

.search-control .btn {
  height: 30px;
  cursor: pointer;
}

footer {
  background-color: var(--primary-bg-1);
  color: var(--primary-label-1);
  min-height: 120px;
  position: relative;
  bottom: 0px;
  margin-top: 30px;
  padding-bottom: 35px;
}

.footer-links {
  list-style: none;
  width: 70%;
}

.footer-link-item a {
  text-decoration: none;
  color: var(--primary-label-1);
  font-weight: 300;
  letter-spacing: 1px;
}

.social-media-links {
  width: 17%;
  padding-top: 15px;
}

.social-media-links a img {
  height: 35px;
  width: 35px;
}

.text-header {
  font-size: 35px;
  font-weight: 400;
  word-wrap: break-word;
  color: white;
}

.poster .text-header {
  color: var(--primary-label-2);
}

.text-description {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  text-align: justify;
}

.logo {
  text-decoration: none;
  color: var(--secondary-label-2) !important;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

body {
  overflow-x: hidden;
}

.text-align-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}


.carousel-container {
  margin-top: 30px;
}

.carousel {
  margin: 20px;
  width: 90%;
  padding-left: 25px;
  border-radius: 7px;
  min-height: 300px;
  box-shadow: 0 0 5px 0 rgba(156, 156, 156, 0.2);
  position: relative;
  left: 2.5%;
  background: inherit;
  color: var(--primary-label-1);
  overflow: hidden;
}

.carousel::before{
  margin: -20px;
  border-radius: 7px;
  content: "";
  position: absolute;
  background: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(124, 124, 124, 0.5);
  filter: blur(10px);
  z-index: -1;
}

.display_message{
  display: flex;
  flex-direction: column;
    align-items: flex-end;
    width: 89%;
  max-height: 380px;
  overflow-y: scroll;
  flex: 3;
  padding-right: 50px;
}

.display_message p {
  order: 1px solid var(--secondary-bg-2);
    padding: 10px;
    border-radius: 15px;
    background-color: white;
    color: black;
    width: fit-content !important;
}

.chatBox {
  height: 50px;
  width: 70%;
}

.chatBox textarea,
.chatBox button {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 50px;
}

.chatBox button {
  justify-content: center;
}

.chat-area {
  flex-direction: column-reverse;
  align-items: flex-end;
  flex: 1;
  height: 50px;
  max-height: 50px;
}

.user-dashboard-container {
  height: calc(100vh - 195px);
}

.side-bar-navigation {
  flex-direction: column;
  height: calc(100vh - 50px);
  position: absolute;
  width: 280px;
  background-color: var(--color-light);
  box-shadow: 0px 0px 3px 2px var(--primary-label-1);
  display: none;
  z-index: 2000000;
  top: 57px;
}

.side-bar-navigation ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.side-bar-navigation li {
  font-size: 28px;
  padding: 20px;
  color: var(--primary-bg) !important;
  font-family: 'Roboto', 'sans-seriff';
  font-weight: 300;
}

.side-bar-navigation a {
  color: var(--primary-bg) !important;
}


/* responsive css for all pages */
@media only screen and (max-device-width: 480px){ 
  .nav-header {
    display: none;
  }

  .menu-header {
    display: flex !important;
    height: 55px;
    background-color: var(--primary-bg);
  }

  .menu-header img {
    padding: 10px;
  }

  .header-title {
    justify-content: flex-end;
    font-size: 22px;
    height: 45px;
    padding: 5px;
  }

  .carousel-container{
    position:relative;
    right: 2%;
  }
}