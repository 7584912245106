/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #ffffff;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
.container {
    display: grid;
    /* grid-template-columns: auto; */
  
    background-color: var(--primary-bg-1);
    width: auto;
  }
  .heading {
    display: inline-grid;
    text-align: center;
    padding: 30px;
    color: var(--primary-label-1);
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .column {
    width: 30%;
    padding: 10px;
    height: 100px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .flex-container {
    margin : auto;
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: max-content;
  }
  .inner-flex-container {
    display: flex;
    flex-flow: column;
    padding: 5px;
    width: 32%;
  }
  .services-heading{
    height: 120px;
    padding: 5px;
    color: azure;
  }
  .responsive {
width: 80%;
max-height: 200px;
margin: 0px auto;
  }
  .services-text{
    font-size: 1rem;
    text-align: justify;
  text-justify: inter-word;
  padding: 5px;
  color: azure;
  }
