

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
body {
    font-family: Arial;
    font-size: 17px;
    color: black;
    padding: 8px;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  
  .col-25 {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
  }
  
  .col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
  }
  
  .col-75 {
    -ms-flex: 75%; /* IE10 */
    flex: 75%;
  }
  
  .col-25,
  .col-50,
  .col-75 {
    padding: 0 16px;
  }
  
  .container {
    background-color: aliceblue;
    color: #757575;
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
  }
  
  input[type=text] {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  label {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
  }
  
  .btn {
    background-color: deepskyblue;
    color: white;
    padding: 12px;
    margin: auto;
    border: none;
    width: 50%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  
  .btn:hover {
    background-color: #106fdb;
  }
  
  .Cart{
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom right, #E3F0FF, #FAFCFF);
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
   }

  .Cart-Container{
    width: 90%;
    height: 95%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 25px 40px #1687d933;
   }

   .Header{
    margin: auto;
    width: 90%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   .Heading{
    font-size: 20px;
    font-family: ‘Open Sans’;
    font-weight: 700;
    color: #2F3841;
   }
   .Action{
    font-size: 14px;
    font-family: ‘Open Sans’;
    font-weight: 600;
    color: #E44C4C;
    cursor: pointer;
    border-bottom: 1px solid #E44C4C;
   }
   .Cart-Items{
    margin: auto;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   .image-box{
    width: 15%;
    text-align: center;
   }
   .about{
    height: 50%;
   }
   .title{
    padding-top: 5px;
    line-height: 5px;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 800;
    color: #202020;
   }
   .subtitle{
    line-height: 5px;
    font-size: 12px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #909090;
   }
   .prices{
    height: 50%;
    text-align: right;
   }
   .amount{
    padding-top: 10px;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 800;
    color: #202020;
   }
   .save{
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #1687d9;
    cursor: pointer;
   }
   .remove{
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #E44C4C;
    cursor: pointer;
   }
   hr{
    width: 66%;
    float: right;
    margin-right: 5%;
   }
   .checkout{
    float: right;
    margin-right: 5%;
    width: 28%;
   }
   .total{
    width: 100%;
    display: flex;
    justify-content: space-between;
   }
   .Subtotal{
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
    color: #202020;
   }
   .items{
    font-size: 10px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #909090;
    line-height: 10px;
   }
   .total-amount{
    font-size: 18px;
    font-family: 'Open Sans';
    font-weight: 900;
    color: #202020;
   }
  a {
    color: #2196F3;
  }
  
  hr {
    border: 1px solid lightgrey;
  }
  
  span.price {
    float: right;
    color: grey;
  }
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
  @media (max-width: 800px) {
    .row {
      flex-direction: column-reverse;
    }
    .col-25 {
      margin-bottom: 20px;
    }
  }
  .heading {
    display: inline-grid;
    text-align: center;
    padding: 30px;
    color: black;
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .column {
    width: 30%;
    padding: 10px;
    height: 100px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .flex-container {
    margin : auto;
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: max-content;
  }
  .inner-flex-container {
    display: flex;
    flex-flow: column;
    padding: 5px;
    width: 32%;
  }
  .services-heading{
    height: 120px;
    padding: 5px;
  }
  .responsive {
width: 80%;
max-height: 200px;
margin: 0px auto;
  }
  .services-text{
    font-size: 1rem;
    text-align: justify;
  text-justify: inter-word;
  padding: 5px;
  }
