.schedule-container{
    width: 100%;
}
input[type="text"] , input[type="number"] {
    flex: 1;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    resize: vertical;
  }
  select{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    resize: vertical;
  }
  textarea {
    width: 100% !important;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 100px;
    margin: 15px;
  }

.submit,
.clear {
  width: 15% !important;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.subscribe{
    width: 14%;
    border-radius: 12px;
    margin-top: 15px;
    cursor: pointer;
    margin-right: 20px;
    background-color: inherit;
}

.clear:hover {
    border-color: var(--state-danger);
    color: var(--state-danger);
}

.submit:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}
.subscribe:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}

.schedule{
    font-size: 17px !important;
    font-weight: 300 !important;
}

.schedule-dropoff div{ 
    margin: 15px;
    flex: 1;
}

.schedule-dropoff input{
    height: 25px;
    padding: 5px;
}

.schedule-dropoff input:first-child {
    flex: 2;
    margin-right: 10px;
}

.schedule-dropoff input:last-child {
    flex: 1;
}
.chatMessage{
    justify-content: flex-end;
}
.dayTime{
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* @media only screen and (max-device-width: 480px){
    .container{
      width: 102%;
      height: 150px !important;
    }
    .media{
      display: flex;    
      justify-content: space-around;
      flex-direction: column;
    }
    .schedule-container{
      width: 60%;
      transform: scale(1);
    }
    .schedule {
    font-size: 20px !important;
    font-weight: 300 !important;
    justify-content: center;
  }
  .sideNavHeader {
    font-size: 40px !important;
  }
  .sideNav {
    width: 400px !important;
    font-size: 28px !important;
  }
  .submit,
  .clear {
    width: 25% !important;
  }
  .info-content .chat-container {
    height: calc(100vh - 100px)  !important;
  }
  .display_message {
    max-height: 80%;
  }
  .display_message p{
    font-size: 35px;
    border-radius: 20px;
  }
  .display_message textarea {
    width: 90% !important;
  }
  .chatBox {
    width: 75%;
    transform: scale(1.5);
  }
  .clear:hover {
    border-color: var(--state-danger);
    color: var(--state-danger);
}
  .submit:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}
} */

@media only screen and (max-device-width: 480px){
  .container{
    width: 104%;
    height: 100px !important;
  }
  .media{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .schedule-container{
    width: 100%;
    transform: scale(1);
  }
  .schedule {
  font-size: 20px !important;
  font-weight: 300 !important;
  justify-content: center;
}
.heading {
  font-size: 25px !important;
  margin-left: 100px;
}
.sideNavHeader {
  font-size: 25px !important;
  padding: 10px 0px !important;
}
.sideNav {
  width: 100% !important;
  font-size: 15px !important;
  height: 140% !important;
}
.submit,
.clear {
  width: 25% !important;
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}
.submit:hover {
  border-color: var(--state-success);
  color:var(--state-success);
}
}