/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/

body {
    /* use the font */
    font-family: "Roboto", sans-serif;
    background-color: var(--primary-bg);
    color: var(--primary-label);
    margin: 0;
    background-attachment: fixed;
  }
  
  /* login, registration and forgot password section and container styles */
  
  .login-section,
  .registration-section,
  .forgotPassword-section {
    align-items: center;
    height: calc(100vh - 85px);
  }
  
  .login-container,
  .registration-container {
    border-radius: 5px;
    min-height: 500px;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.4);
    background-color: var(--color-light);
    color: var(--primary-label-2) !important;
  }
  
  .forgotPassword-container {
    border-radius: 5px;
    min-height: 40%;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.4);
    background-color: var(--color-light);
    color: var(--primary-label-2) !important;
  }

  .registration-container {
    padding-bottom: 25px;
  }
  
  .login-container .login-header,
  .registration-container .registration-header,
  .forgotPassword-container .forgotPassword-header {
    font-size: 36px;
    font-weight: 400;
    margin-top: 45px;
    padding-bottom: 10px;
  }
  
  .login-container input,
  .registration-container input,
  .forgotPassword-container input {
    width: 300px;
    height: 35px;
    margin: 20px 45px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    padding: 3px;
    border: 0;
    border-bottom: 1px solid var(--primary-label-1) !important;
  }
  
  .login-container input:focus-visible,
  .registration-container input:focus-visible,
  .forgotPassword-container input:focus-visible {
    outline: none;
  }
  
  .registration-container .name-input {
    width: 148px;
  }
  .name-input:first-child {
    margin-right: 0px;
  }
  .name-input:last-child {
    margin-left: 5px;
  }
  
  /* 
  login and register button styles */
  
  .login-btn,
  .register-btn,
  .continue-btn {
    width: 75%;
    height: 40px;
    margin-top: 20px;
    color: var(--secondary-bg-1) !important;
    cursor: pointer;
    font-size: 15px;
    border-radius: 20px !important;
  }
  
  /* responsive css for login and registration page */
  @media only screen and (max-device-width: 480px){
    .login-section{
      justify-content: flex-start !important;
      flex-direction: column  !important;
      align-items: center !important;
      min-height: 100%;
    }
  
    .login-section div.image-container{
      display: none;
    }
  
    .login-container {
      transform: scale(.8);
    }
  
  
  
    .forgotPassword-section{
      height: 1000px;
    }
    
    .registration-section{
      justify-content: flex-start !important;
      flex-direction: column  !important;
      min-height: 100%;
    }
  
    .registration-section div.image-container{
      display: none;
    }
  
    .registration-container {
      transform: scale(.8);
    }
  
    body {
      overflow-x: hidden;
  }
  }

  .link-style {
    cursor: pointer;
    font-weight: 500;
    color: var(--primary-bg-1);
  }
  

  .authentication-section {
    background-color: var(--primary-bg);
  }