.chatbot{
    width: 100% !important;
}
.display_message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-height: 360px;
  overflow-y: scroll;
  flex: 3;
  padding-right: 50px;
}

.display_message p {
  order: 1px solid var(--secondary-bg-2);
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  color: black;
  width: fit-content !important;
}

.chatBox {
  height: 50px;
  width: 100%;
}

.chatBox textarea,
.chatBox button {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 50px;
}

.chatBox button {
  justify-content: center;
}

.chat-area {
  flex-direction: column-reverse;
  align-items: flex-end;
  flex: 1;
  height: 50px;
  max-height: 50px;
}

.user-dashboard-container {
    height: calc(100vh - 195px);
  }
  

  @media only screen and (max-device-width: 480px) {
    .info-content .chat-container {
      height: calc(100vh - 150px)  !important;
    }
    .display_message {
      max-height: 100%;
    }
    .display_message p{
      font-size: 35px;
      border-radius: 20px;
    }
    .display_message textarea {
      width: 100% !important;
    }
    .chatBox {
      width: 100%;
      transform: scale(1);
    }
    .clear:hover {
      border-color: var(--state-danger);
      color: var(--state-danger);
  }
  }