@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
 * All the Color Variables for ui elements will be defined in here 
*/

:root {
  /* Background colors */
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;

  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;

  /* Label colors */

  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;

  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;

  /* State colors */
  --state-danger: #c62828;
  --state-danger-1: #b71c1c;
  --state-danger-2: #ef5350;
  --state-danger-3: #f44336;
  --state-danger-light: #ffebee;

  --state-warning: #f9a825;
  --state-warning-1: #f57f17;
  --state-warning-2: #fdd835;
  --state-warning-3: #ffff00;
  --state-warning-light: #fffde7;

  --state-success: #558b2f;
  --state-success-1: #33691e;
  --state-success-2: #8bc34a;
  --state-success-3: #b2ff59;
  --state-success-light: #f1f8e9;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-damion {
  font-family: 'Damion', cursive;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}
.align-items-between {
  align-items: space-between;
}
.align-items-around {
  align-items: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.btn {
  background-color: inherit;
  border-radius: 3px;
  padding: 5px 10px;
  border: 1px solid #01579b;
  border: 1px solid var(--secondary-bg-1);
  color: #fafafa;
  color: var(--primary-label);
}

header.nav-header {
  width: 100%;
  padding: 10px;
  background-color: #252525;
  background-color: var(--primary-bg-1);
  position: -webkit-sticky;
  position: sticky; /* Set the navbar to fixed position */
  top: 0;
  z-index: 1000;
}

.navbar {
  flex: 6 1;
}

.navbar ul {
  list-style: none;
  align-items: center;
}

.nav-Link {
  padding: 10px;
  border-radius: 4px;
}

.nav-Link:hover {
  background-color: #2c2c2c;
  background-color: var(--primary-bg);
  color: #fafafa;
  color: var(--primary-label);
}

.nav-item .nav-Link {
  text-decoration: none;
  color: #cccaca;
  color: var(--primary-label-1);
  font-weight: 300;
  letter-spacing: 1px;
}

.nav-item.active .nav-Link {
  color: #fafafa !important;
  color: var(--primary-label) !important;
  border: 1px solid #039be5;
  border: 1px solid var(--secondary-label-2);
  border-radius: 4px;
}

.nav-item:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.header-title {
  align-items: center;
  flex: 2 1;
  padding-left: 10px;
  font-size: 25px;
  font-weight: 400;
  color: #039be5;
  color: var(--secondary-label-2);
  letter-spacing: 1px;
}

.search-control {
  margin-bottom: 3px;
}
.search-control input {
  height: 30px;
  margin-right: 7px;
}

.search-control .btn {
  height: 30px;
  cursor: pointer;
}

footer {
  background-color: #252525;
  background-color: var(--primary-bg-1);
  color: #cccaca;
  color: var(--primary-label-1);
  min-height: 120px;
  position: relative;
  bottom: 0px;
  margin-top: 30px;
  padding-bottom: 35px;
}

.footer-links {
  list-style: none;
  width: 70%;
}

.footer-link-item a {
  text-decoration: none;
  color: #cccaca;
  color: var(--primary-label-1);
  font-weight: 300;
  letter-spacing: 1px;
}

.social-media-links {
  width: 17%;
  padding-top: 15px;
}

.social-media-links a img {
  height: 35px;
  width: 35px;
}

.text-header {
  font-size: 35px;
  font-weight: 400;
  word-wrap: break-word;
  color: white;
}

.poster .text-header {
  color: #616161;
  color: var(--primary-label-2);
}

.text-description {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  text-align: justify;
}

.logo {
  text-decoration: none;
  color: #039be5 !important;
  color: var(--secondary-label-2) !important;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

body {
  overflow-x: hidden;
}

.text-align-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}


.carousel-container {
  margin-top: 30px;
}

.carousel {
  margin: 20px;
  width: 90%;
  padding-left: 25px;
  border-radius: 7px;
  min-height: 300px;
  box-shadow: 0 0 5px 0 rgba(156, 156, 156, 0.2);
  position: relative;
  left: 2.5%;
  background: inherit;
  color: #cccaca;
  color: var(--primary-label-1);
  overflow: hidden;
}

.carousel::before{
  margin: -20px;
  border-radius: 7px;
  content: "";
  position: absolute;
  background: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(124, 124, 124, 0.5);
  -webkit-filter: blur(10px);
          filter: blur(10px);
  z-index: -1;
}

.display_message{
  display: flex;
  flex-direction: column;
    align-items: flex-end;
    width: 89%;
  max-height: 380px;
  overflow-y: scroll;
  flex: 3 1;
  padding-right: 50px;
}

.display_message p {
  order: 1px solid #40c4ff;
  order: 1px solid var(--secondary-bg-2);
    padding: 10px;
    border-radius: 15px;
    background-color: white;
    color: black;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.chatBox {
  height: 50px;
  width: 70%;
}

.chatBox textarea,
.chatBox button {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 50px;
}

.chatBox button {
  justify-content: center;
}

.chat-area {
  flex-direction: column-reverse;
  align-items: flex-end;
  flex: 1 1;
  height: 50px;
  max-height: 50px;
}

.user-dashboard-container {
  height: calc(100vh - 195px);
}

.side-bar-navigation {
  flex-direction: column;
  height: calc(100vh - 50px);
  position: absolute;
  width: 280px;
  background-color: #ffffff;
  background-color: var(--color-light);
  box-shadow: 0px 0px 3px 2px #cccaca;
  box-shadow: 0px 0px 3px 2px var(--primary-label-1);
  display: none;
  z-index: 2000000;
  top: 57px;
}

.side-bar-navigation ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.side-bar-navigation li {
  font-size: 28px;
  padding: 20px;
  color: #2c2c2c !important;
  color: var(--primary-bg) !important;
  font-family: 'Roboto', 'sans-seriff';
  font-weight: 300;
}

.side-bar-navigation a {
  color: #2c2c2c !important;
  color: var(--primary-bg) !important;
}


/* responsive css for all pages */
@media only screen and (max-device-width: 480px){ 
  .nav-header {
    display: none;
  }

  .menu-header {
    display: flex !important;
    height: 55px;
    background-color: #2c2c2c;
    background-color: var(--primary-bg);
  }

  .menu-header img {
    padding: 10px;
  }

  .header-title {
    justify-content: flex-end;
    font-size: 22px;
    height: 45px;
    padding: 5px;
  }

  .carousel-container{
    position:relative;
    right: 2%;
  }
}

#snackbar {
    visibility: hidden; 
    min-width: 250px; 
    margin-left: -125px;
    background-color: rgb(154, 211, 122);
    color: rgb(37, 37, 37); 
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
  }
  #snackbar.danger{
    background-color: rgb(214, 103, 103);
    color: white;
  }
  #snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
#tray {
    height:40px;
    width:40px;
    background-color:rgb(0, 140, 255);
    display:block;
    border-radius:50%;
    text-align:center;
    cursor:pointer;
    transition-duration:0.5s;
    left: 25px;
    bottom: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
    
    #tray:hover {
    box-shadow: 0 0 10px rgb(141, 141, 141);
    }
    
    #tray i {
    color: orange;
    margin-top: 8px;
    font-size: 20px;
    }
    .count {
    position: absolute;
    background-color: rgb(248, 141, 0);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 2px;
    color: rgb(20, 19, 19);
    font-weight: 300;
    display:none;
    }

    .logout-tab{
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding-bottom: 5px;
    }
  
    .logout-tab p {
      color: var(--primary-label-1);
    }
  
    .logout-tab img{
      padding-left: 10px;
    }
/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
body {
    /* use the font */
    font-family: "Roboto", sans-serif;
    background-color: var(--primary-bg);
    color: var(--primary-label);
    margin: 0;
    background-attachment: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }
  
  .poster {
    min-height: 300px;
    background-color: var(--color-light);
    color: var(--primary-label-2);
  }
  
  /* carousel slideshow styles */
  .carousel-container {
    margin-top: 30px;
  }
  
  .carousel {
    margin: 20px;
    width: 90%;
    padding-left: 25px;
    border-radius: 7px;
    min-height: 300px;
    box-shadow: 0 0 5px 0 rgba(156, 156, 156, 0.2);
    position: relative;
    left: 2.5%;
    background: inherit;
    color: var(--primary-label-1);
    overflow: hidden;
  }
  
  .carousel::before {
    margin: -20px;
    border-radius: 7px;
    content: "";
    position: absolute;
    background: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(124, 124, 124, 0.5);
    -webkit-filter: blur(10px);
            filter: blur(10px);
    z-index: -1;
  }
  
  /* section-break styles */
  
  .page-break {
    margin: 0px 30px;
    border-color: var(--primary-bg-2);
  }
  
  /* info section styles */
  
  .info-link {
    min-height: 300px;
    padding: 0px 30px;
    align-items: center;
  }
  
  .info-link img {
    height: 450px;
    width: 450px;
  }
  
  .services-link {
    text-decoration: none;
    color: var(--primary-label-1);
    cursor: pointer;
    font-size: 18px;
    margin-top: 15px;
    font-weight: 300;
  }
  
  .services-link:hover {
    color: var(--color-light) !important;
    text-decoration: underline;
  }
  
  .about-description {
    color: var(--primary-label-1);
    font-size: 18px;
    font-weight: 300;
  }
  .info-link a .arrow-right {
    height: 12px;
    width: 12px;
  }
  
  .slides {
    display: none;
  }
  
  /* The pills indicators */
  
  .pill-container {
    text-align: center;
    position: relative;
    top: 2%;
  }
  .pills {
    cursor: pointer;
    height: 3px;
    width: 25px;
    margin: 2px 1px;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .pills:hover {
    background-color: var(--secondary-label-1);
    border-color: var(--primary-bg) !important;
  }

  .home-carousel .pills.active{
    background-color: var(--color-light)  !important;
    border-color: var(--primary-bg) !important;
  }
  
  /* carousel offer section styles */
  
  .offer-info {
    font-size: 60px !important;
    margin: 10px 0px;
  }
  
  /* carousel customer review section styles */
  
  .review-content div:first-child {
    font-size: 30px;
    font-weight: 300;
    margin-top: 15px;
  }
  
  .customer-review {
    margin-top: 50px;
  }
  .customer-review span {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .customer-review span img {
    height: 25px;
    width: 70px;
  }
  
  /* carousel price listing section styles */
  .price-listing span {
    font-size: 18px;
  }
  
  
  /* responsive css for home page */
  @media only screen and (max-device-width: 480px){
    .about-description, .text-description {
      display: none;
    }
  
    .poster {
      padding-right: 15px;
      align-items: center;
    }
  
    .text-header {
      font-size: 24px !important;
    }
  
    body {
      overflow-x: hidden;
  }

  .info-section-container img{
    width: 200px;
    height: 200px;
  }
}

  .info-section-container {
    background-color: var(--primary-bg);
  }
  

/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/

body {
    /* use the font */
    font-family: "Roboto", sans-serif;
    background-color: var(--primary-bg);
    color: var(--primary-label);
    margin: 0;
    background-attachment: fixed;
  }
  
  /* login, registration and forgot password section and container styles */
  
  .login-section,
  .registration-section,
  .forgotPassword-section {
    align-items: center;
    height: calc(100vh - 85px);
  }
  
  .login-container,
  .registration-container {
    border-radius: 5px;
    min-height: 500px;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.4);
    background-color: var(--color-light);
    color: var(--primary-label-2) !important;
  }
  
  .forgotPassword-container {
    border-radius: 5px;
    min-height: 40%;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.4);
    background-color: var(--color-light);
    color: var(--primary-label-2) !important;
  }

  .registration-container {
    padding-bottom: 25px;
  }
  
  .login-container .login-header,
  .registration-container .registration-header,
  .forgotPassword-container .forgotPassword-header {
    font-size: 36px;
    font-weight: 400;
    margin-top: 45px;
    padding-bottom: 10px;
  }
  
  .login-container input,
  .registration-container input,
  .forgotPassword-container input {
    width: 300px;
    height: 35px;
    margin: 20px 45px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    padding: 3px;
    border: 0;
    border-bottom: 1px solid var(--primary-label-1) !important;
  }
  
  .login-container input:focus-visible,
  .registration-container input:focus-visible,
  .forgotPassword-container input:focus-visible {
    outline: none;
  }
  
  .registration-container .name-input {
    width: 148px;
  }
  .name-input:first-child {
    margin-right: 0px;
  }
  .name-input:last-child {
    margin-left: 5px;
  }
  
  /* 
  login and register button styles */
  
  .login-btn,
  .register-btn,
  .continue-btn {
    width: 75%;
    height: 40px;
    margin-top: 20px;
    color: var(--secondary-bg-1) !important;
    cursor: pointer;
    font-size: 15px;
    border-radius: 20px !important;
  }
  
  /* responsive css for login and registration page */
  @media only screen and (max-device-width: 480px){
    .login-section{
      justify-content: flex-start !important;
      flex-direction: column  !important;
      align-items: center !important;
      min-height: 100%;
    }
  
    .login-section div.image-container{
      display: none;
    }
  
    .login-container {
      -webkit-transform: scale(.8);
              transform: scale(.8);
    }
  
  
  
    .forgotPassword-section{
      height: 1000px;
    }
    
    .registration-section{
      justify-content: flex-start !important;
      flex-direction: column  !important;
      min-height: 100%;
    }
  
    .registration-section div.image-container{
      display: none;
    }
  
    .registration-container {
      -webkit-transform: scale(.8);
              transform: scale(.8);
    }
  
    body {
      overflow-x: hidden;
  }
  }

  .link-style {
    cursor: pointer;
    font-weight: 500;
    color: var(--primary-bg-1);
  }
  

  .authentication-section {
    background-color: var(--primary-bg);
  }
/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
body {
    /* use the font */
    font-family: "Roboto", sans-serif;
    color: var(--primary-bg);
    margin: 0;
    background-attachment: fixed;
  }
  
  /* manager and admin page container styles */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px;
    color: var(--primary-label-1);
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
    background-color: var(--primary-bg-1);
  }
  
  .section-header {
    font-family: "Roboto", "sans-serif";
    font-size: 24px;
    font-weight: 300px;
    letter-spacing: 1px;
  }
  
  .section-container {
    margin-bottom: 40px;
  }
  
  .section-content {
    flex: 1 1;
    margin-right: 30px;
    width: 48%;
  }
  
  .manage-container {
    width: 100%;
    height: 100%;
    padding: 40px 0px 40px 40px;
  }
  
  /* Table styles */
  .material-table {
    min-height: 400px;
    box-shadow: 0px 0px 3px 3px rgba(156, 156, 156, 0.2);
    width: 100%;
    border-spacing: 0px;
    height: 400px;
    max-height: 420px;
    overflow: scroll;
    /* border:1px solid var(--primary-label-1) */
  }
  
  .table-container {
    max-height: 420px;
    height: 400px;
    overflow: scroll;
    width: 95%;
    margin-top: 20px;
  }
  .material-table tr {
    height: 40px;
    max-height: 40px;
    border-bottom: 1px solid var(--primary-bg);
    padding-left: 25px;
  }
  
  .material-table tr:first-child {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #464646 !important;
    color: white !important;
  }
  
  .material-table tr:nth-child(odd) {
    background-color: #d9d8d8;
    color: var(--primary-bg-1);
  }
  
  .material-table td:first-child,
  .material-table th:first-child {
    padding-left: 25px !important;
  }
  
  .material-table td,
  .material-table th {
    text-align: left;
    padding: 10px;
    border-collapse: collapse;
  }
  
  .material-table td {
    font-size: 18px;
    font-weight: 400;
    color: var(--primary-label-2);
  }
  
  .material-table th {
    font-size: 18px;
    font-weight: 300;
  }
  
  .material-table td:last-child {
    width: 70px;
    min-width: 70px;
  }
  
  .material-table td input {
    width: 100px;
    padding: 6px;
    font: "Roboto", "sans-serfiff";
    border-radius: 13px;
    border: 1px solid var(--primary-bg-3);
  }
  
  /* styles for edit delete and add actions in table */
  .action-icons img {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  
  /* Manual order section styles */
  .order-container {
    padding: 20px 20px 20px 0px;
  }
  
  .order-container div:first-child {
    margin-right: 50px;
  }
  
  .order-container input {
    height: 30px;
    padding: 5px;
    margin-bottom: 25px;
    border-radius: 3px;
    border: 1px solid var(--primary-bg-3);
  }
  
  .order-container select {
    padding: 5px;
    height: 40px;
    width: 255px;
    border-radius: 3px;
    border: 1px solid var(--primary-bg-3);
  }
  
  .order-container label {
    margin-bottom: 10px;
  }
  
  .btn.submit-order {
    color: var(--secondary-bg-1);
    border-color: var(--secondary-bg-1);
    border-radius: 17px;
    height: 35px !important;
  }
  
  /* Admin page graph and stats section styles */
  
  .graph-view {
    height: 600px;
    max-height: 600px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .graph-view .carousel-container {
    border: 1px solid var(--primary-bg-3);
    box-shadow: 0px 0px 3px 0px var(--primary-bg-3);
  }
  
  .graph-view img {
    height: 300px;
    width: 90%;
  }
  
  .number-card {
    width: 70%;
    margin-right: 70px;
    height: 100px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid var(--primary-bg-3);
    box-shadow: 0px 0px 2px 0px var(--primary-bg-3);
  }
  
  .number-container {
    margin-left: 65px;
    margin-top: 10px;
  }
  
  .slides {
    display: flex;
    justify-content: center;
  }
  
  .pill-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .pill-container .pills {
    height: 10px;
    width: 20px;
    border: 1px solid var(--secondary-bg);
    border-radius: 15px;
    margin-right: 8px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .admin-container .pill-container .pills.active {
    background-color: var(--primary-bg) !important;
    border-color: var(--primary-bg) !important;
  }

  .pills:hover {
    border-color: var(--secondary-label-1) !important;
  }
  
  .carousel-container {
    width: 90%;
    padding: 25px;
  }
  
  .number-card span:first-child {
    font-size: 26px;
    font-weight: 300;
    padding-bottom: 20px;
  }
  
  /* responsive css for manager and admin page */
  @media only screen and (max-device-width: 480px) {
    .container, .section-header{
      font-size: 24px !important;
    }

    .section-container {
      flex-direction: column !important;
      margin-bottom: 30px;
    }
  
    .section-content {
      width: 100% !important;
      margin-bottom: 30px;
    }
  
    .graph-view {
        height: 1000px !important;
        max-height: unset !important;
        margin-bottom: 20px;
    }

    .graph-view img {
      width: 80%;  
    }

    .number-card {
        height: 200px !important;
        margin-top: 70px;
        width: 33%;
        margin-right: 10px;
    }
    .number-card span:first-child {
        font-size: 24px;
    }
  
    .number-card span:last-child {
      font-size: 30px;
    }
    .number-container {
        flex-wrap: wrap;
        margin-left: 10px;
    }
  
    .btn.submit-order {
        height: 48px  !important;
    }
  
    body {
        overflow-x: hidden;
    }

    .manage-container{
      padding: 10px !important;
    }

    .order-container{
      flex-direction: column !important;
    }

    .order-container select{
      margin-bottom: 15px;
    }

    .carousel-container{
      width: 80%;
    }
  }

  .section-header {
    font-family: "Oswald", sans-serif;
    font-weight: 300 !important;
  }

  .administration-bg {
    background-color: var(--color-light);
    overflow-x: clip;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .action-icons img:first-child {
    content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3ElEQVRoge1YPWgUQRQ+Y6OFivEn2Z8kEgsl6O3MbiIKYlAEwc7ixJ9wO2+FQ9TCQrEMNhLBUrDxrxaxEK0UxMYmIGgR0U4bQQQRD/yN+mZ3dm8v2TOX3dnbU+eDgePt3nvf92bezJstlRQUFBQUFBT+c/RZ7gbTqhq5BhncemS1TmBvlmGOHOuN+zQJGzMImzEo/OJDJ2y6n8BILgLQ+eMwUOphsz0N8jCOtrpB2Sz6vqNTuO+/Q+C9brlEugCeKQzyFTM5lXYMONWNzeQx6zacjJKEv9H2MxcRwVSzT1n9xMkHGWczQ2Nuf/gcZ+OEEPG6d9vRlVnjRZAhICJP4IdG4DD6vJIogsIFf3aodzw7c4GsAuLkdeoeEuYlSSJ4rQRFDVNSyPtOMwhoQT5ETAS8GKCejsvodjAD895Nj7QCWpFH20RfeWL9PBEYQ8zIk1KlsrRQAa3Ia5Sd4oXKt+boZSSLa/+VWDrP8TxYJ408x2IFaA7s9LOJ5Hm2Iz821HzyFD7gchkNrJM9aLuZVNCFCOg68osR0JXk2xXQas0nn7AN8vE1rzm1tRjnHMbb32EBkz1I5K3YSc7Hn6CAg2h7l5T5uQVrlr0tYkaud1SA5jA71rjVTertij9fs8lbMZd80rIpTIA/7f7hw+7xDhOXy0dsBXY0v8XJsxt/2iqLE0DgIQ/MLyd8/YsirvO6aJBfuGALEWBuryzHjH7GwC9DW1yEYXm7291tihFgs30i6OWm/9hwWhT1bLsnbCEC0H6JBzVtOKDZrhPUA3uArcK3Vn1/VwlAos9EAX9p7EQ86+wp/u8i39OHxt1l7cQpaAbEDYvAGxzX+GUlbTNWiADNcTfzISNOYduoLPz1AgxaLYsauirXcYcE6JZ3VnzkOiPVsS+AsO/o/FZeA1vtR2HXKr21Rqd3M3+ZW3jwa+Y0bwylkveBd9bBURjOcww7tVXyiSsoKCgo/Mv4DbopWknH/Bb5AAAAAElFTkSuQmCC);
  }
  .action-icons img:last-child {
    content:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvUlEQVRoge2Zv0rEQBDGY2UtaGOlIAgWNlodm5kLWFwjvoKFhZWdjWCp2PgANqJYCBaiXpI7EAsVVMReH8NCxD/I+e2hx5E7vbiZEIT5wRD4GGbm201S7HqeoihKLQjGQ+bdiPkgVRDtxcZMFj13CztUSPSEwe7SBHIf8Twteu4WWP0YA93klZ8Ju9VoONMjbjHQfYq8v+X7finb8JVKPwq94RVpFBUxs8lk4tj3R8MgmCoisPsTmYZPEhPNYkfmRYu2EZbLc1j1hbzq5/7x5f5x/9QAv8V1rNxWZr0wAznrYqgBR10MNeCoi6EGHHUx1ICjLoYacNTFUAOOuhhqwFEXQw046mKoAUddDDXgqIuhBhx1MVB8PyR66NI4ipivOnSiKuI6bb4dHlGXm7iz8TIaf9SYR9p1ewDb7RAWudP2ODKpV4n80PepXTthHkT+K3qsiQ/+zVGpNNy8yGA+bHhen2Rt1NyxJ+F15jHJut0aLTaPvvE6xcYMZa0XGjOARdn+qrkiMWPvpkRLiBcbaHye+m4sEVjxMzyfUecdz1XpXf0Ve2+AphuIiyjl3VgyMPglTGyK3wMoivJ/+ASfl1JdgsYJ1QAAAABJRU5ErkJggg==);
  }

  .action-icons img{
    width: 25px;
    height: 25px;
  }
  
.chatbot{
    width: 100% !important;
}
.display_message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-height: 360px;
  overflow-y: scroll;
  flex: 3 1;
  padding-right: 50px;
}

.display_message p {
  order: 1px solid var(--secondary-bg-2);
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  color: black;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.chatBox {
  height: 50px;
  width: 100%;
}

.chatBox textarea,
.chatBox button {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 50px;
}

.chatBox button {
  justify-content: center;
}

.chat-area {
  flex-direction: column-reverse;
  align-items: flex-end;
  flex: 1 1;
  height: 50px;
  max-height: 50px;
}

.user-dashboard-container {
    height: calc(100vh - 195px);
  }
  

  @media only screen and (max-device-width: 480px) {
    .info-content .chat-container {
      height: calc(100vh - 150px)  !important;
    }
    .display_message {
      max-height: 100%;
    }
    .display_message p{
      font-size: 35px;
      border-radius: 20px;
    }
    .display_message textarea {
      width: 100% !important;
    }
    .chatBox {
      width: 100%;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    .clear:hover {
      border-color: var(--state-danger);
      color: var(--state-danger);
  }
  }
.createClub-container {
    width: 200%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.createClub-container {
    width: 200%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.createClub-container {
    width: 200%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.createClub-container {
    width: 200%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.sideNav {
    height: 130%;
    width: 250px;
    z-index: 1;
    left: 0;
    background-color: var(--primary-bg-1);
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  .sideNav ul {
    list-style: none;
    align-items: center;
  }
  
  .sideNavItem {
    color: var(--primary-label-1);
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .sideNavItem a {
    color: var(--primary-label-1);
    text-decoration: none;
  }
  .sideNavItem.active .sideNav-link {
    color: var(--primary-label) !important;
    border-bottom: 4px solid var(--secondary-label-2);
    border-radius: 4px;
  }
  
  .sideNavItem:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .sideNavHeader {
    height: 50px;
    color: var(--primary-bg-1);
    font-size: 26px !important;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 25px 0px;
  }
.joinClub-container {
    width: 100%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.createClub-container {
    width: 200%;
  }

.subscribe-form input[type="text"],
.subscribe-form input[type="number"],
.subscribe-form input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.club {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.club:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
#snackbar-service {
  visibility: hidden; 
  min-width: 250px; 
  margin-left: -125px;
  background-color: rgb(154, 211, 122);
  color: rgb(37, 37, 37); 
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar-service.danger{
  background-color: rgb(214, 103, 103);
  color: white;
}


#snackbar-service.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
input[type="text"],
input[type="number"],
input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.subscribe {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.subscribe:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.register-container {
    width: 150%;
}
input[type="text"],
input[type="number"],
input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.subscribe {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.subscribe:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
@media only screen and (max-device-width: 480px) {
    .register-container {
    width: 90%;
    -webkit-transform: scale(1);
            transform: scale(1);
    margin-top: 20px;
  }
  .registerHeader{
    font-size: 10px !important;
    padding: 10px 0px;
  }
  .registerSelect{
    width: 100%
  };
  .registerTextArea{
    width: 90% !important;
  }
  input[type="number"]{
    width: 100% !important  ;
  }
}

.subscribe-container {
    width: 100%;
  }

input[type="text"],
input[type="number"],
input [type="email"] {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px;
  resize: vertical;
}
textarea {
  width: 100% !important;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  margin: 15px;
}

.submit,
.clear {
  width: 10%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.subscribe {
  width: 14%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
  color: var(--secondary-bg-1);
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}

.submit:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.subscribe:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.orderButton {
  width: 25%;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.orderButton:hover {
  border-color: var(--state-success);
  color: var(--state-success);
}
.sideNav {
    height: 130%;
    width: 250px;
    z-index: 1;
    left: 0;
    background-color: var(--primary-bg-1);
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  .sideNav ul {
    list-style: none;
    align-items: center;
  }
  
  .sideNavItem {
    color: var(--primary-label-1);
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .sideNavItem a {
    color: var(--primary-label-1);
    text-decoration: none;
  }
  .sideNavItem.active .sideNav-link {
    color: var(--primary-label) !important;
    border-bottom: 4px solid var(--secondary-label-2);
    border-radius: 4px;
  }
  
  .sideNavItem:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .sideNavHeader {
    height: 50px;
    color: var(--primary-bg-1);
    font-size: 26px !important;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 25px 0px;
  }
/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/

.container {
    display: grid;
    background-color: var(--primary-bg-1);
    width: auto;
  }
  .heading { 
    display: inline-grid;
    text-align: center;
    padding: 30px;
    color: var(--primary-label-1);
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .innerHeading {
    display: grid;
    text-align: center;
    color: var(--primary-label-2);
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    height: 300px;
    color: var(--primary-label-2);
    align-items: center;
    height: 100%;
    margin-top: 40px;
  }
  
  .formContainer .field-container {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .contact-form input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    resize: vertical;
  }
  textarea {
    width: 97% !important;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 100px;
    margin: 15px;
  }
  
  .buttons {
    justify-content: flex-end;
  }
  
  .submit,
  .clear {
    border-radius: 12px;
    margin-top: 15px;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .submit {
    color: var(--secondary-bg-1);
  }
  
  .clear {
    color: var(--state-danger);
    border-color: var(--state-danger);
  }
  
  body {
    background-color: white !important;
    height: 100%;
    overflow-y: scroll;
  }
  
  
  /* media query for contact us page for device width less than 480px  */
  @media only screen and (max-device-width: 480px){
    .mediaPhone{
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
    .formContainer .field-container {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      margin-top: 30px;
    }
    textarea {
      width: 100% !important;
    }
    .buttons{
      margin-left: 17px !important;
      justify-content: center;
      flex-direction: row;
      width: 109%;
      margin-bottom: 40px!important;
    }
    .container{
      width: 100%;
    }
    .heading {
      font-size: 40px !important;
    }  
  }
.headings {
    display: inline-grid;
    text-align: left;
    padding: 30px;
    color: var(--primary-label-1);
    font-size: 50px !important;
    font-weight: 300;
    letter-spacing: 1px;
    margin-left: 10%;
    padding-bottom: 0px;
  }
  .learn-btn {
    background-color: inherit;
    border-radius: 3px;
    padding: 5px 10px;
    border: 1px solid var(--secondary-bg-1);
    color: var(--primary-label);
    width: 20%;
    height: 50%;
    margin-bottom: 5%;
    cursor: pointer;
    font-size: 1em;
  }
  .learn-btn:hover {
    background-color: var(--secondary-bg-1);
    border-radius: 3px;
    padding: 5px 10px;
    border: 1px solid var(--secondary-bg-1);
    color: var(--primary-label);
    width: 20%;
    height: 50%;
    margin-bottom: 5%;
    cursor: pointer;
    font-size: 1em;
  }
  .side-Heading{
      color: var(--primary-bg);
      margin-left: 14%;
  }
  .about-content{
      width: 70%;
      margin: auto;
      height: auto;
      border: 0.5px solid lightgrey;
      border-radius: 2px;
      margin-bottom: 10%;
      color: black;
      padding: 5%;
  }

/*  
Aravind Ashok Kumar 1001944888
Neeharika Kosana 1001960736
Vignesh Manikandan 1002012757
*/

/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #ffffff;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
.container {
    display: grid;
    /* grid-template-columns: auto; */
  
    background-color: var(--primary-bg-1);
    width: auto;
  }
  .heading {
    display: inline-grid;
    text-align: center;
    padding: 30px;
    color: var(--primary-label-1);
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .column {
    width: 30%;
    padding: 10px;
    height: 100px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .flex-container {
    margin : auto;
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: -webkit-max-content;
    height: max-content;
  }
  .inner-flex-container {
    display: flex;
    flex-flow: column;
    padding: 5px;
    width: 32%;
  }
  .services-heading{
    height: 120px;
    padding: 5px;
    color: azure;
  }
  .responsive {
width: 80%;
max-height: 200px;
margin: 0px auto;
  }
  .services-text{
    font-size: 1rem;
    text-align: justify;
  text-justify: inter-word;
  padding: 5px;
  color: azure;
  }

.schedule-container{
    width: 100%;
}
input[type="text"] , input[type="number"] {
    flex: 1 1;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    resize: vertical;
  }
  select{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 15px;
    resize: vertical;
  }
  textarea {
    width: 100% !important;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 100px;
    margin: 15px;
  }

.submit,
.clear {
  width: 15% !important;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 20px;
  background-color: inherit;
}
.subscribe{
    width: 14%;
    border-radius: 12px;
    margin-top: 15px;
    cursor: pointer;
    margin-right: 20px;
    background-color: inherit;
}

.clear:hover {
    border-color: var(--state-danger);
    color: var(--state-danger);
}

.submit:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}
.subscribe:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}

.schedule{
    font-size: 17px !important;
    font-weight: 300 !important;
}

.schedule-dropoff div{ 
    margin: 15px;
    flex: 1 1;
}

.schedule-dropoff input{
    height: 25px;
    padding: 5px;
}

.schedule-dropoff input:first-child {
    flex: 2 1;
    margin-right: 10px;
}

.schedule-dropoff input:last-child {
    flex: 1 1;
}
.chatMessage{
    justify-content: flex-end;
}
.dayTime{
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* @media only screen and (max-device-width: 480px){
    .container{
      width: 102%;
      height: 150px !important;
    }
    .media{
      display: flex;    
      justify-content: space-around;
      flex-direction: column;
    }
    .schedule-container{
      width: 60%;
      transform: scale(1);
    }
    .schedule {
    font-size: 20px !important;
    font-weight: 300 !important;
    justify-content: center;
  }
  .sideNavHeader {
    font-size: 40px !important;
  }
  .sideNav {
    width: 400px !important;
    font-size: 28px !important;
  }
  .submit,
  .clear {
    width: 25% !important;
  }
  .info-content .chat-container {
    height: calc(100vh - 100px)  !important;
  }
  .display_message {
    max-height: 80%;
  }
  .display_message p{
    font-size: 35px;
    border-radius: 20px;
  }
  .display_message textarea {
    width: 90% !important;
  }
  .chatBox {
    width: 75%;
    transform: scale(1.5);
  }
  .clear:hover {
    border-color: var(--state-danger);
    color: var(--state-danger);
}
  .submit:hover {
    border-color: var(--state-success);
    color:var(--state-success);
}
} */

@media only screen and (max-device-width: 480px){
  .container{
    width: 104%;
    height: 100px !important;
  }
  .media{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .schedule-container{
    width: 100%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .schedule {
  font-size: 20px !important;
  font-weight: 300 !important;
  justify-content: center;
}
.heading {
  font-size: 25px !important;
  margin-left: 100px;
}
.sideNavHeader {
  font-size: 25px !important;
  padding: 10px 0px !important;
}
.sideNav {
  width: 100% !important;
  font-size: 15px !important;
  height: 140% !important;
}
.submit,
.clear {
  width: 25% !important;
}

.clear:hover {
  border-color: var(--state-danger);
  color: var(--state-danger);
}
.submit:hover {
  border-color: var(--state-success);
  color:var(--state-success);
}
}
@media only screen and (max-device-width: 480px){
    .sideNavHeader {
        font-size: 25px !important;
      }
      .sideNav {
        width:  57% !important;
        font-size: 12px !important;
        height: 140%!important  ;
      }
      

}



/* FONT USED : Roboto Sans Serif , Oswald Sans Serif ,Damion cursive */

/* COLOR USED :
  --primary-bg: #2c2c2c;
  --primary-bg-1: #252525;
  --primary-bg-2: #757575;
  --primary-bg-3: #9e9e9e;
  --secondary-bg: #03a9f4;
  --secondary-bg-1: #01579b;
  --secondary-bg-2: #40c4ff;
  --secondary-bg-3: #b3e5fc;
  --color-light: #ffffff;
  --primary-label: #fafafa;
  --primary-label-1: #cccaca;
  --primary-label-2: #616161;
  --secondary-label: #e1f5fe;
  --secondary-label-1: #80d8ff;
  --secondary-label-2: #039be5;
  --state-danger: #c62828;
  --state-warning: #f9a825;
  --state-success: #558b2f;
*/
body {
    font-family: Arial;
    font-size: 17px;
    color: black;
    padding: 8px;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .row { /* IE10 */
    display: flex; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  
  .col-25 { /* IE10 */
    flex: 25% 1;
  }
  
  .col-50 { /* IE10 */
    flex: 50% 1;
  }
  
  .col-75 { /* IE10 */
    flex: 75% 1;
  }
  
  .col-25,
  .col-50,
  .col-75 {
    padding: 0 16px;
  }
  
  .container {
    background-color: aliceblue;
    color: #757575;
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgrey;
    border-radius: 3px;
  }
  
  input[type=text] {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  label {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
  }
  
  .btn {
    background-color: deepskyblue;
    color: white;
    padding: 12px;
    margin: auto;
    border: none;
    width: 50%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  
  .btn:hover {
    background-color: #106fdb;
  }
  
  .Cart{
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom right, #E3F0FF, #FAFCFF);
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
   }

  .Cart-Container{
    width: 90%;
    height: 95%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 25px 40px #1687d933;
   }

   .Header{
    margin: auto;
    width: 90%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   .Heading{
    font-size: 20px;
    font-family: ‘Open Sans’;
    font-weight: 700;
    color: #2F3841;
   }
   .Action{
    font-size: 14px;
    font-family: ‘Open Sans’;
    font-weight: 600;
    color: #E44C4C;
    cursor: pointer;
    border-bottom: 1px solid #E44C4C;
   }
   .Cart-Items{
    margin: auto;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   .image-box{
    width: 15%;
    text-align: center;
   }
   .about{
    height: 50%;
   }
   .title{
    padding-top: 5px;
    line-height: 5px;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 800;
    color: #202020;
   }
   .subtitle{
    line-height: 5px;
    font-size: 12px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #909090;
   }
   .prices{
    height: 50%;
    text-align: right;
   }
   .amount{
    padding-top: 10px;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 800;
    color: #202020;
   }
   .save{
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #1687d9;
    cursor: pointer;
   }
   .remove{
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #E44C4C;
    cursor: pointer;
   }
   hr{
    width: 66%;
    float: right;
    margin-right: 5%;
   }
   .checkout{
    float: right;
    margin-right: 5%;
    width: 28%;
   }
   .total{
    width: 100%;
    display: flex;
    justify-content: space-between;
   }
   .Subtotal{
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
    color: #202020;
   }
   .items{
    font-size: 10px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #909090;
    line-height: 10px;
   }
   .total-amount{
    font-size: 18px;
    font-family: 'Open Sans';
    font-weight: 900;
    color: #202020;
   }
  a {
    color: #2196F3;
  }
  
  hr {
    border: 1px solid lightgrey;
  }
  
  span.price {
    float: right;
    color: grey;
  }
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
  @media (max-width: 800px) {
    .row {
      flex-direction: column-reverse;
    }
    .col-25 {
      margin-bottom: 20px;
    }
  }
  .heading {
    display: inline-grid;
    text-align: center;
    padding: 30px;
    color: black;
    font-size: 30px !important;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .column {
    width: 30%;
    padding: 10px;
    height: 100px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .flex-container {
    margin : auto;
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: -webkit-max-content;
    height: max-content;
  }
  .inner-flex-container {
    display: flex;
    flex-flow: column;
    padding: 5px;
    width: 32%;
  }
  .services-heading{
    height: 120px;
    padding: 5px;
  }
  .responsive {
width: 80%;
max-height: 200px;
margin: 0px auto;
  }
  .services-text{
    font-size: 1rem;
    text-align: justify;
  text-justify: inter-word;
  padding: 5px;
  }

