.sideNav {
    height: 130%;
    width: 250px;
    z-index: 1;
    left: 0;
    background-color: var(--primary-bg-1);
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  .sideNav ul {
    list-style: none;
    align-items: center;
  }
  
  .sideNavItem {
    color: var(--primary-label-1);
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .sideNavItem a {
    color: var(--primary-label-1);
    text-decoration: none;
  }
  .sideNavItem.active .sideNav-link {
    color: var(--primary-label) !important;
    border-bottom: 4px solid var(--secondary-label-2);
    border-radius: 4px;
  }
  
  .sideNavItem:hover {
    transform: scale(1.1);
  }
  
  .sideNavHeader {
    height: 50px;
    color: var(--primary-bg-1);
    font-size: 26px !important;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 25px 0px;
  }